export const SupportedChainId = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  BSC: 56,
  BSCTN: 97,
  ETHPOW: 10001,
  ETHFAIR: 513100,
  AVALANCHE: 43114,
  ARBITRUM_ONE: 42161,
  ARBITRUM_NOVA: 42170,
  ARBITRUM_RINKEBY: 421611,
  AURORA: 1313161554,
  OPTIMISM: 10,
  OPTIMISTIC_KOVAN: 69,
  GNOSIS: 100,
  POLYGON: 137,
  POLYGON_MUMBAI: 80001,
  PULS: 369,
  CELO: 42220,
  CELO_ALFAJORES: 44787,
  FANTOM: 250,
  LINEA: 59144,
  ZKEVM: 1101,
  ZKSYNC: 324,
  BASE: 8453,
  SCROLL: 534352,
  ZORA: 7777777,
  XDAI: 100,
  OPBNB: 204,
  MANTLE: 5000,
  MODE: 34443,
  ANCIENT8: 888888888,
  MANTA: 169,
  MOONBEAM: 1284,
  TAIKO: 167000,
  ZETA: 7000,
  POLYMARKET: 137,
  BLAST: 81457,
};
export const ALL_RPC_URLS = {
  [SupportedChainId.BSCTN]: ["https://bsc-testnet.public.blastapi.io"],
  [SupportedChainId.BSC]: [
    "https://bsc-pokt.nodies.app",
    "https://bsc.meowrpc.com",
    "https://binance.llamarpc.com",
  ],
  [SupportedChainId.MAINNET]: [
    "https://eth-mainnet.g.alchemy.com/v2/yYTw1_F8WgexT7cSRC6NDltqftxhFaws",
    // "https://rpc.ankr.com/eth",
    // "https://eth.meowrpc.com",
    // "https://eth.merkle.io",
    // "https://api.stateless.solutions/ethereum/v1/demo",
  ],
  [SupportedChainId.OPTIMISM]: [
    "https://optimism.llamarpc.com",
    "https://optimism-rpc.publicnode.com",
    "https://ethereum-rpc.publicnode.com",
  ],
  [SupportedChainId.POLYGON]: [
    "https://polygon.llamarpc.com",
    "https://polygon-rpc.com",
  ],
  [SupportedChainId.LINEA]: [
    "https://linea.blockpi.network/v1/rpc/public",
    "https://rpc.linea.build",
  ],
  // [SupportedChainId.ARBITRUM_ONE]: [
  //   "https://arb-mainnet.g.alchemy.com/v2/yYTw1_F8WgexT7cSRC6NDltqftxhFaws",
  //   "https://arb-pokt.nodies.app",
  //   "https://arbitrum.blockpi.network/v1/rpc/public",
  //   "https://arbitrum-one.public.blastapi.io",
  //   "https://arb-mainnet.g.alchemy.com/v2/yYTw1_F8WgexT7cSRC6NDltqftxhFaws",
  //   "https://arbitrum-one-rpc.publicnode.com",
  //   "https://arbitrum.meowrpc.com",
  //   "https://api.stateless.solutions/arbitrum-one/v1/demo",
  // ],
  [SupportedChainId.ARBITRUM_ONE]: [
    // "https://arb-mainnet.g.alchemy.com/v2/yYTw1_F8WgexT7cSRC6NDltqftxhFaws",
    "https://arb1.arbitrum.io/rpc",
    "https://arbitrum.meowrpc.com",
    "https://arbitrum-one-rpc.publicnode.com",
    "https://arbitrum-one.publicnode.com",
  ],
  [SupportedChainId.ZKEVM]: ["https://zkevm-rpc.com"],
  [SupportedChainId.ZKSYNC]: ["https://mainnet.era.zksync.io"],
  [SupportedChainId.BASE]: [
    "https://base.llamarpc.com",
    "https://base-rpc.publicnode.com",
  ],
  [SupportedChainId.ARBITRUM_NOVA]: [
    // 'https://arbitrum-nova.drpc.org',
    "https://arbitrum-nova.publicnode.com",
    "https://nova.arbitrum.io/rpc",
  ],
  [SupportedChainId.SCROLL]: [
    "https://rpc.scroll.io",
    // 'https://scroll.drpc.org',
  ],
  [SupportedChainId.ZORA]: ["https://rpc.zora.energy"],
  [SupportedChainId.FANTOM]: [
    "https://fantom-pokt.nodies.app",
    "https://fantom-rpc.publicnode.com",
    // 'https://fantom.drpc.org',
  ],
  [SupportedChainId.CELO]: [
    "https://forno.celo.org",
    "https://rpc.ankr.com/celo",
  ],
  [SupportedChainId.AVALANCHE]: [
    "https://1rpc.io/avax/c",
    "https://rpc.ankr.com/avalanche",
  ],
  [SupportedChainId.MOONBEAM]: ["https://moonbeam-rpc.publicnode.com"],
  [SupportedChainId.MANTA]: [
    "https://manta-pacific.drpc.org",
    "https://rpc.ankr.com/avalanche",
  ],
  [SupportedChainId.MODE]: ["https://mainnet.mode.network"],
  [SupportedChainId.GNOSIS]: [
    "https://gnosis-rpc.publicnode.com",
    "https://gnosis-pokt.nodies.app",
  ],
  [SupportedChainId.ANCIENT8]: ["https://rpc.ancient8.gg"],
  [SupportedChainId.ZETA]: [
    "https://zetachain-evm.blockpi.network/v1/rpc/public",
    "https://zetachain-mainnet-archive.allthatnode.com:8545",
  ],
  [SupportedChainId.TAIKO]: [
    "https://rpc.mainnet.taiko.xyz",
    "https://rpc.taiko.tools",
    "https://rpc.ankr.com/taiko",
  ],
};
